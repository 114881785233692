<template>
  <div class="baseInfoForm-container">
    <form-panel
      ref="formPanel"
      :form="form"
      v-bind="submitConfig"
      :submitUrl="submitUrl"
      submitText="保存"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
    >
      <el-form-item
        label="附件"
        :rules="[
          { required: true, message: '请上传相关资料', trigger: 'blur' },
        ]"
        prop="annex"
      >
        <v-uploader2
          :action="uploadURL"
          filePath="customerFiles"
          v-model="form.annex"
          tip="最多允许上传10个文件,单个文件大小不允许超过50MB"
          :limit="10"
          :beforeRemove="beforeRemove"
          :fileSize="500"
        />
      </el-form-item>
    </form-panel>
  </div>
</template>
<script>
import { addCommunityAnnexInfoURL, uploadURL } from "./api";
import { CheckboxPlus } from "components/bussiness";
import { vUploader2 } from "components/control";
export default {
  components: { CheckboxPlus, vUploader2 },
  props: {
    publicParams: Object,
    responseData: Object,
  },
  data() {
    return {
      uploadURL,
      width: 260,
      submitUrl: addCommunityAnnexInfoURL,
      form: {
        annexId: "",
        communityId: "",
        annex: [],
      },
      submitConfig: {
        submitMethod: "post",
      },
    };
  },
  mounted() {
    this.responseData && this.responseData.data && this.setBaseInfo();
  },
  methods: {
    setBaseInfo() {
      let { communityAnnexV } = this.responseData.data
      let keyArr = Object.keys(this.form);
      let newObj = {};
      keyArr.forEach((value) => {
        newObj[value] = communityAnnexV[value];
      });
      this.form = newObj;
    },
    submitBefore(data) {
      console.log(data, "data");
      return true
    },
    submitSuccess(data) {
      return true;
    },
    async beforeRemove() {
      // 上传文件删除前
      const isOk = await this.$confirm("此操作将永久删除该文件, 是否继续？");
      return isOk;
    },
  },
};
</script>

<style lang="scss" scoped>
.baseInfoForm-container {
  height: 100%;
}
</style>
