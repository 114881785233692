<template>
  <div class="baseInfoForm-container">
    <form-panel ref="formPanel" :form="form"  v-bind="submitConfig" :submitUrl="submitUrl" submitText="保存" :submitBefore="submitBefore" :submitSuccess="submitSuccess">
      <el-form-item label="总建筑面积" :rules="[{ required: true, message: '请输入总建筑面积', trigger: 'blur' }]" prop="communityArea">
        <v-input-number v-model="form.communityArea" placeholder="请输入总建筑面积" :max="999999999.99" :precision="2" :width="width"></v-input-number> m²
      </el-form-item>
      <el-form-item label="总用地面积" :rules="[{ required: true, message: '请输入总用地面积', trigger: 'blur' }]" prop="landUseArea">
        <v-input-number v-model="form.landUseArea" placeholder="请输入总用地面积" :max="999999999.99" :precision="2" :width="width"></v-input-number> m²
      </el-form-item>
      <el-form-item label="占地面积" :rules="[{ required: true, message: '请输入占地面积', trigger: 'blur' }]" prop="landOccupationArea">
        <v-input-number v-model="form.landOccupationArea" placeholder="请输入占地面积" :max="999999999.99" :precision="2" :width="width"></v-input-number> m²
      </el-form-item>
      <el-form-item label="四至边界" :rules="[{ required: true, message: '请输入四至边界', trigger: 'blur' }]" prop="fourBoundary">
        <v-input v-model="form.fourBoundary" placeholder="请输入四至边界" :width="lwidth"></v-input>
      </el-form-item>  
      <el-form-item label="地上面积" :rules="[{ required: true, message: '请输入地上面积', trigger: 'blur' }]" prop="landUpperArea">
        <v-input-number v-model="form.landUpperArea" placeholder="请输入地上面积" :max="999999999.99" :precision="2" :width="width"></v-input-number> m²
      </el-form-item>
      <el-form-item label="地下面积" :rules="[{ required: true, message: '请输入地下面积', trigger: 'blur' }]" prop="landDownArea">
        <v-input-number v-model="form.landDownArea" placeholder="请输入地下面积" :max="999999999.99" :precision="2" :width="width"></v-input-number> m²
      </el-form-item>
      <el-form-item label="总收费面积" :rules="[{ required: true, message: '请输入总收费面积', trigger: 'blur' }]" prop="chargeArea">
        <v-input-number v-model="form.chargeArea" placeholder="请输入总收费面积" :max="999999999.99" :precision="2" :width="width"></v-input-number> m²
      </el-form-item>
      <el-form-item label="规划绿化面积" :rules="[{ required: true, message: '请输入规划绿化面积', trigger: 'blur' }]" prop="planGreenArea">
        <v-input-number v-model="form.planGreenArea" placeholder="请输入规划绿化面积" :max="999999999.99" :precision="2" :width="width"></v-input-number> m²
      </el-form-item>
      <el-form-item label="实测绿化面积" :rules="[{ required: true, message: '请输入实测绿化面积', trigger: 'blur' }]" prop="actualGreenArea">
        <v-input-number v-model="form.actualGreenArea" placeholder="请输入实测绿化面积" :max="999999999.99" :precision="2" :width="width"></v-input-number> m²
      </el-form-item>
      <el-form-item label="绿化率" :rules="[{ required: true, message: '请输入绿化垂直投影面积之和与小区用地的比率', trigger: 'blur' }]" prop="greenRate">
        <v-input v-model="form.greenRate" placeholder="绿化垂直投影面积之和与小区用地的比率" :width="lwidth"></v-input>
      </el-form-item>
      <el-form-item label="水系面积" :rules="[{ required: true, message: '请输入水系面积', trigger: 'blur' }]" prop="waterArea">
        <v-input-number v-model="form.waterArea" placeholder="请输入水系面积" :max="999999999.99" :precision="2" :width="width"></v-input-number> m²
      </el-form-item>
      <el-form-item label="泳池面积" :rules="[{ required: true, message: '请输入泳池面积', trigger: 'blur' }]" prop="poolArea">
        <v-input-number v-model="form.poolArea" placeholder="请输入泳池面积" :max="999999999.99" :precision="2" :width="width"></v-input-number> m²
      </el-form-item>
      <el-form-item label="容积率" :rules="[{ required: true, message: '请输入地上总建筑面积与用地面积的比率', trigger: 'blur' }]" prop="volumeRate">
        <v-input v-model="form.volumeRate" placeholder="地上总建筑面积与用地面积的比率" :width="lwidth"></v-input>
      </el-form-item>
      <el-form-item label="物业服务用房位置" :rules="[{ required: true, message: '请输入物业服务用房位置', trigger: 'blur' }]" prop="propertyServicesHousePosition">
        <v-input v-model="form.propertyServicesHousePosition" placeholder="请输入物业服务用房位置" :width="lwidth"></v-input>
      </el-form-item>     
      <el-form-item label="物业服务用房面积" :rules="[{ required: true, message: '请输入物业服务用房面积', trigger: 'blur' }]" prop="propertyServicesHouseArea">
        <v-input-number v-model="form.propertyServicesHouseArea" placeholder="请输入物业服务用房面积" :max="999999999.99" :precision="2" :width="width"></v-input-number> m²
      </el-form-item>
      <el-form-item label="经营性用房位置" :rules="[{ required: true, message: '请输入经营性用房位置', trigger: 'blur' }]" prop="managementHousePosition">
        <v-input v-model="form.managementHousePosition" placeholder="请输入经营性用房位置" :width="lwidth"></v-input>
      </el-form-item>     
      <el-form-item label="经营性用房面积" :rules="[{ required: true, message: '请输入经营性用房面积', trigger: 'blur' }]" prop="managementHouseArea">
        <v-input-number v-model="form.managementHouseArea" placeholder="请输入经营性用房面积" :max="999999999.99" :precision="2" :width="width"></v-input-number> m²
      </el-form-item>
      <el-form-item label="会馆地址" :rules="[{ required: true, message: '请输入会馆地址', trigger: 'blur' }]" prop="hallAddress">
        <v-input v-model="form.hallAddress" placeholder="请输入会馆地址" :width="lwidth"></v-input>
      </el-form-item>     
      <el-form-item label="会馆面积" :rules="[{ required: true, message: '请输入会馆面积', trigger: 'blur' }]" prop="hallArea">
        <v-input-number v-model="form.hallArea" placeholder="请输入会馆面积" :max="999999999.99" :precision="2" :width="width"></v-input-number> m²
      </el-form-item>
      <el-form-item label="社区用房面积" :rules="[{ required: true, message: '请输入社区用房面积', trigger: 'blur' }]" prop="communityHouseArea">
        <v-input-number v-model="form.communityHouseArea" placeholder="请输入社区用房面积" :max="999999999.99" :precision="2" :width="width"></v-input-number> m²
      </el-form-item>
    </form-panel>
  </div>
</template>
<script>
import {
  addCommunityAreaInfoURL
} from './api'

export default {
  props: {
    publicParams: Object,
    responseData: Object
  },
  data () {
    return {
      width: 180,
      lwidth: 300,
      submitUrl: addCommunityAreaInfoURL,
      form: {
        areaId: '',
        communityId: '',    
        communityArea: undefined, //总建筑面积
        landUseArea: undefined,   //总用地面积
        landOccupationArea: undefined, //占地面积
        fourBoundary: '', //四至边界
        landUpperArea: undefined, //地上面积
        landDownArea: undefined, //地下面积
        chargeArea: undefined, //总收费面积
        planGreenArea: undefined, //规划绿化面积
        actualGreenArea: undefined, //实测绿化面积
        greenRate: '', //绿化率
        waterArea: undefined, //水系面积
        poolArea: undefined, //泳池面积
        volumeRate: '', //容积率
        propertyServicesHousePosition: '', //物业服务用房位置
        propertyServicesHouseArea: undefined, //物业服务用房面积
        managementHousePosition: '', //经营性用房位置
        managementHouseArea: undefined, //经营性用房面积
        hallAddress: '', //会馆地址
        hallArea: undefined, //会馆面积
        communityHouseArea: undefined, //社区用房面积
      },
      submitConfig: {
        submitMethod: 'post'
      }
    }
  },
  computed: {
    // 修改项目基础信息权限
    baseDisabled () {
      let baseEdit = this.$store.getters.getPermission('updateCommunityBase')
      return !baseEdit
    }
  },
  mounted () {
    this.responseData && this.responseData.data && this.setBaseInfo()
  },
  methods: {
    setBaseInfo () {
      let { communityAreaV } = this.responseData.data
      let keyArr = Object.keys(this.form)
      let newObj = {}
      keyArr.forEach(value => {
        newObj[value] = communityAreaV[value]
      })
      this.form = newObj
    },
    submitBefore (data) {
      console.log(data,'data')
      return true
    },
    submitSuccess (data) {
      if (this.form.areaId) {
        this.$message.success('修改成功')
        this.$emit('contact', {
          method: 'setActiveLabel',
          data: '业户信息'
        })     
        return false
      } else {
        this.$message.success('保存成功')
        this.$emit('contact', {
            method: 'setActiveLabel',
            data: '业户信息'
          })
        return false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.baseInfoForm-container {
  height: 100%;
}
</style>
