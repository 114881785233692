var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "baseInfoForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            staticClass: "form-container",
            attrs: {
              form: _vm.form,
              submitUrl: _vm.submitUrl,
              submitText: "保存",
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccess,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "项目名称",
                rules: [
                  {
                    required: true,
                    message: "请输入项目名称",
                    trigger: "blur",
                  },
                ],
                prop: "communityName",
              },
            },
            [
              _c("v-input", {
                attrs: {
                  placeholder: "请输入项目名称",
                  width: _vm.lwidth,
                  disabled: _vm.baseDisabled,
                },
                model: {
                  value: _vm.form.communityName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "communityName", $$v)
                  },
                  expression: "form.communityName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "所属公司",
                rules: [
                  {
                    required: true,
                    message: "请选择所属公司",
                    trigger: "change",
                  },
                ],
                prop: "tenantId",
              },
            },
            [
              _c(
                "v-select2",
                _vm._b(
                  {
                    attrs: {
                      width: _vm.width,
                      disabled: _vm.baseDisabled,
                      placeholder: "查询公司名称",
                    },
                    model: {
                      value: _vm.form.tenantId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "tenantId", $$v)
                      },
                      expression: "form.tenantId",
                    },
                  },
                  "v-select2",
                  _vm.regionParams,
                  false
                )
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "项目区划",
                rules: [
                  {
                    required: true,
                    validator: _vm.validateAreaCode,
                    trigger: "change",
                  },
                ],
                prop: "shequCode",
              },
            },
            [
              _c("area-select2", {
                attrs: {
                  value: _vm.areaValue,
                  placeholder: "按区域选择",
                  width: 500,
                },
                on: {
                  "update:value": function ($event) {
                    _vm.areaValue = $event
                  },
                  change: _vm.areaChange,
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "项目地址",
                rules: [
                  {
                    required: true,
                    message: "请输入项目地址",
                    trigger: "blur",
                  },
                ],
                prop: "communityAddress",
              },
            },
            [
              _c("v-input", {
                attrs: { placeholder: "请输入项目地址", width: 500 },
                model: {
                  value: _vm.form.communityAddress,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "communityAddress", $$v)
                  },
                  expression: "form.communityAddress",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "项目类型",
                rules: [
                  {
                    required: true,
                    message: "请选择项目类型",
                    trigger: "change",
                  },
                ],
                prop: "communityType",
              },
            },
            [
              _c("v-select", {
                attrs: {
                  options: _vm.communityTypeOps,
                  width: _vm.width,
                  disabled: _vm.baseDisabled,
                },
                model: {
                  value: _vm.form.communityType,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "communityType", $$v)
                  },
                  expression: "form.communityType",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "项目编号",
                rules: [
                  {
                    required: true,
                    message: "请输入项目编号",
                    trigger: "blur",
                  },
                ],
                prop: "communityNumber",
              },
            },
            [
              _c("v-input", {
                attrs: { placeholder: "请输入项目编号", width: _vm.lwidth },
                model: {
                  value: _vm.form.communityNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "communityNumber", $$v)
                  },
                  expression: "form.communityNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "所属区域",
                rules: [
                  {
                    required: true,
                    message: "请输入所属区域",
                    trigger: "blur",
                  },
                ],
                prop: "communityRegion",
              },
            },
            [
              _c("v-input", {
                attrs: { placeholder: "请输入所属区域", width: _vm.lwidth },
                model: {
                  value: _vm.form.communityRegion,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "communityRegion", $$v)
                  },
                  expression: "form.communityRegion",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "项目地名办备案名",
                rules: [
                  {
                    required: true,
                    message: "请输入项目地名办备案名",
                    trigger: "blur",
                  },
                ],
                prop: "communityPlace",
              },
            },
            [
              _c("v-input", {
                attrs: {
                  placeholder: "请输入项目地名办备案名",
                  width: _vm.lwidth,
                },
                model: {
                  value: _vm.form.communityPlace,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "communityPlace", $$v)
                  },
                  expression: "form.communityPlace",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "项目销售名" } },
            [
              _c("v-input", {
                attrs: { placeholder: "请输入项目销售名", width: _vm.lwidth },
                model: {
                  value: _vm.form.communitySaleName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "communitySaleName", $$v)
                  },
                  expression: "form.communitySaleName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "合同甲方",
                rules: [
                  {
                    required: true,
                    message: "请输入物业合同甲方",
                    trigger: "blur",
                  },
                ],
                prop: "contractFirstParty",
              },
            },
            [
              _c("v-input", {
                attrs: { placeholder: "请输入物业合同甲方", width: _vm.lwidth },
                model: {
                  value: _vm.form.contractFirstParty,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "contractFirstParty", $$v)
                  },
                  expression: "form.contractFirstParty",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "项目来源",
                rules: [
                  {
                    required: true,
                    message: "请选择项目来源",
                    trigger: "change",
                  },
                ],
                prop: "communitySource",
              },
            },
            [
              _c("checkbox-plus", {
                attrs: {
                  value: _vm.form.communitySource,
                  type: "radio",
                  options: _vm.projectFromOptions,
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(_vm.form, "communitySource", $event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "行人出入口", prop: "pedestrian" } },
            [
              _vm._l(_vm.form.pedestrian, function (item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c("v-input", {
                      model: {
                        value: item.doorName,
                        callback: function ($$v) {
                          _vm.$set(item, "doorName", $$v)
                        },
                        expression: "item.doorName",
                      },
                    }),
                    _c("v-select", {
                      attrs: { options: _vm.doorTypeListOptions },
                      model: {
                        value: item.typeName,
                        callback: function ($$v) {
                          _vm.$set(item, "typeName", $$v)
                        },
                        expression: "item.typeName",
                      },
                    }),
                  ],
                  1
                )
              }),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.addDoor("pedestrian")
                        },
                      },
                    },
                    [_vm._v("增加")]
                  ),
                  _vm.form.pedestrian.length > 1
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "danger", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.delDoor("pedestrian")
                            },
                          },
                        },
                        [_vm._v("删除")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-form-item",
            { attrs: { label: "机动车出入入口", prop: "motor" } },
            [
              _vm._l(_vm.form.motor, function (item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c("v-input", {
                      model: {
                        value: item.doorName,
                        callback: function ($$v) {
                          _vm.$set(item, "doorName", $$v)
                        },
                        expression: "item.doorName",
                      },
                    }),
                    _c("v-select", {
                      attrs: { options: _vm.doorTypeListOptions },
                      model: {
                        value: item.typeName,
                        callback: function ($$v) {
                          _vm.$set(item, "typeName", $$v)
                        },
                        expression: "item.typeName",
                      },
                    }),
                  ],
                  1
                )
              }),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.addDoor("motor")
                        },
                      },
                    },
                    [_vm._v("增加")]
                  ),
                  _vm.form.motor.length > 1
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "danger", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.delDoor("motor")
                            },
                          },
                        },
                        [_vm._v("删除")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-form-item",
            { attrs: { label: "非机动车出入口", prop: "nonMotor" } },
            [
              _vm._l(_vm.form.nonMotor, function (item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c("v-input", {
                      model: {
                        value: item.doorName,
                        callback: function ($$v) {
                          _vm.$set(item, "doorName", $$v)
                        },
                        expression: "item.doorName",
                      },
                    }),
                    _c("v-select", {
                      attrs: { options: _vm.doorTypeListOptions },
                      model: {
                        value: item.typeName,
                        callback: function ($$v) {
                          _vm.$set(item, "typeName", $$v)
                        },
                        expression: "item.typeName",
                      },
                    }),
                  ],
                  1
                )
              }),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.addDoor("nonMotor")
                        },
                      },
                    },
                    [_vm._v("增加")]
                  ),
                  _vm.form.nonMotor.length > 1
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "danger", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.delDoor("nonMotor")
                            },
                          },
                        },
                        [_vm._v("删除")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-form-item",
            { attrs: { label: "消防出入口", prop: "fireFighting" } },
            [
              _vm._l(_vm.form.fireFighting, function (item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c("v-input", {
                      model: {
                        value: item.doorName,
                        callback: function ($$v) {
                          _vm.$set(item, "doorName", $$v)
                        },
                        expression: "item.doorName",
                      },
                    }),
                    _c("v-select", {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { options: _vm.doorTypeListOptions },
                      model: {
                        value: item.typeName,
                        callback: function ($$v) {
                          _vm.$set(item, "typeName", $$v)
                        },
                        expression: "item.typeName",
                      },
                    }),
                  ],
                  1
                )
              }),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.addDoor("fireFighting")
                        },
                      },
                    },
                    [_vm._v("增加")]
                  ),
                  _vm.form.fireFighting.length > 1
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "danger", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.delDoor("fireFighting")
                            },
                          },
                        },
                        [_vm._v("删除")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "园区公告栏数",
                rules: [
                  {
                    required: true,
                    message: "请输入园区公告栏数",
                    trigger: "blur",
                  },
                ],
                prop: "communityNoticeNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入园区公告栏数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.communityNoticeNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "communityNoticeNum", $$v)
                  },
                  expression: "form.communityNoticeNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "项目楼幢类型概述",
                rules: [
                  {
                    required: true,
                    message: "请输入项目楼幢类型",
                    trigger: "blur",
                  },
                ],
                prop: "buildingTypeDescribe",
              },
            },
            [
              _c("v-input", {
                attrs: {
                  placeholder:
                    "请输入项目楼幢类型（如，1-5幢为多层，6-8幢为别墅等）",
                  width: 500,
                },
                model: {
                  value: _vm.form.buildingTypeDescribe,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "buildingTypeDescribe", $$v)
                  },
                  expression: "form.buildingTypeDescribe",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "总楼幢数",
                rules: [
                  {
                    required: true,
                    message: "请输入总楼幢数",
                    trigger: "blur",
                  },
                ],
                prop: "buildingNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入总楼幢数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.buildingNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "buildingNum", $$v)
                  },
                  expression: "form.buildingNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "储藏间总数",
                rules: [
                  {
                    required: true,
                    message: "请输入储藏间总数",
                    trigger: "blur",
                  },
                ],
                prop: "storeNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入储藏间总数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.storeNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "storeNum", $$v)
                  },
                  expression: "form.storeNum",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }