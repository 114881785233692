import { generateMapByOpts } from 'common/utils'

// 项目状态
const communityStatusOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '正常',
    value: 0
  },
  {
    text: '关闭',
    value: 1
  },
  {
    text: '作废',
    value: 2
  }
]

const communityStatusMap = generateMapByOpts(communityStatusOps)

// 组织标签
const projectFromOps = [
  {
    label: '集团',
    value: 1
  },
  {
    label: '外拓',
    value: 2
  }
]

const deliverTypeOps = [
  {
    label: '一次性交付',
    value: 1
  },
  {
    label: '滚动交付',
    value: 2
  }
]

const isYesOps = [
  {
    label: '是',
    value: '1'
  },
  {
    label: '否',
    value: '0'
  }
]

const propertyFeePricePrincipleOps = [
  {
    label: '政府指导价',
    value: 1
  },
  {
    label: '市场调节价',
    value: 2
  }
]

const energyConsumptionFeeTypeOps = [
  {
    label: '定额',
    value: 1
  },
  {
    label: '按时分摊',
    value: 2
  }
]

const propertyTypeOps = [
  {
    label: '包干',
    value: 1
  },
  {
    label: '酬金',
    value: 2
  }
]
const billingModeOps = [
  {
    label: '一费制',
    value: 1
  },
  {
    label: '非一费制',
    value: 2
  }
]
const businessModelOps = [
  {
    label: '全委',
    value: 1
  },
  {
    label: '单向',
    value: 2
  }
]

const doorTypeList = [
  {
    text: '人防',
    value: '人防'
  }, {
    text: '技防',
    value: '技防'
  }
]

export {
  communityStatusOps,
  communityStatusMap,
  projectFromOps,
  deliverTypeOps,
  isYesOps,
  propertyFeePricePrincipleOps,
  energyConsumptionFeeTypeOps,
  propertyTypeOps,
  billingModeOps,
  businessModelOps,
  doorTypeList
}
