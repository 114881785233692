var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "baseInfoForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitUrl: _vm.submitUrl,
              submitText: "保存",
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccess,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "总户数",
                rules: [
                  {
                    required: true,
                    message: "请输入项目规划的住宅与商铺总户数",
                    trigger: "blur",
                  },
                ],
                prop: "totalHouseNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入项目规划的住宅与商铺总户数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.totalHouseNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "totalHouseNum", $$v)
                  },
                  expression: "form.totalHouseNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "未售户数",
                rules: [
                  {
                    required: true,
                    message: "请输入未售户数",
                    trigger: "blur",
                  },
                ],
                prop: "notSellNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入未售户数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.notSellNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "notSellNum", $$v)
                  },
                  expression: "form.notSellNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "已售未领户数",
                rules: [
                  {
                    required: true,
                    message: "请输入已售未领户数",
                    trigger: "blur",
                  },
                ],
                prop: "sellNotReceiveNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入已售未领户数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.sellNotReceiveNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sellNotReceiveNum", $$v)
                  },
                  expression: "form.sellNotReceiveNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "已交付户数（领房）",
                rules: [
                  {
                    required: true,
                    message: "请输入已交付的户数",
                    trigger: "blur",
                  },
                ],
                prop: "deliveryCount",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入已交付的户数",
                  max: 99999,
                  width: _vm.width,
                  disabled: _vm.baseDisabled,
                },
                model: {
                  value: _vm.form.deliveryCount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "deliveryCount", $$v)
                  },
                  expression: "form.deliveryCount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "已交付面积",
                rules: [
                  {
                    required: true,
                    message: "请输入已交付面积数",
                    trigger: "blur",
                  },
                ],
                prop: "deliverHouseArea",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入已交付面积数",
                  max: 999999999.99,
                  precision: 2,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.deliverHouseArea,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "deliverHouseArea", $$v)
                  },
                  expression: "form.deliverHouseArea",
                },
              }),
              _vm._v(" m²\n    "),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "交付方式",
                rules: [
                  {
                    required: true,
                    message: "请选择交付方式",
                    trigger: "change",
                  },
                ],
                prop: "deliverType",
              },
            },
            [
              _c("checkbox-plus", {
                attrs: {
                  value: _vm.form.deliverType,
                  type: "radio",
                  options: _vm.deliverTypeOptions,
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(_vm.form, "deliverType", $event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "交付日期",
                prop: "deliverDate",
                rules: [
                  {
                    required: true,
                    message: "请输入交付日期",
                    trigger: "change",
                  },
                ],
              },
            },
            [
              _c("v-input", {
                attrs: { placeholder: "请输入交付日期", width: _vm.width },
                model: {
                  value: _vm.form.deliverDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "deliverDate", $$v)
                  },
                  expression: "form.deliverDate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "在装修户数",
                rules: [
                  {
                    required: true,
                    message: "请输入在装修户数",
                    trigger: "blur",
                  },
                ],
                prop: "renovationHouseNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入在装修户数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.renovationHouseNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "renovationHouseNum", $$v)
                  },
                  expression: "form.renovationHouseNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "装修未住户数",
                rules: [
                  {
                    required: true,
                    message: "请输入装修未住户数",
                    trigger: "blur",
                  },
                ],
                prop: "renovationNotLiveNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入装修未住户数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.renovationNotLiveNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "renovationNotLiveNum", $$v)
                  },
                  expression: "form.renovationNotLiveNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "已入住户数",
                rules: [
                  {
                    required: true,
                    message: "请输入已入住户数",
                    trigger: "blur",
                  },
                ],
                prop: "totalLiveRoomCount",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入已入住户数",
                  max: 99999,
                  width: _vm.width,
                  disabled: _vm.baseDisabled,
                },
                model: {
                  value: _vm.form.totalLiveRoomCount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "totalLiveRoomCount", $$v)
                  },
                  expression: "form.totalLiveRoomCount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "常住户数",
                rules: [
                  {
                    required: true,
                    message: "请输入常住户数",
                    trigger: "blur",
                  },
                ],
                prop: "oftenHouseNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入常住户数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.oftenHouseNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "oftenHouseNum", $$v)
                  },
                  expression: "form.oftenHouseNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "业委会成立日期" } },
            [
              _c("v-input", {
                attrs: {
                  placeholder: "请输入业委会成立日期",
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.committeeDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "committeeDate", $$v)
                  },
                  expression: "form.committeeDate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "总人口数",
                rules: [
                  {
                    required: true,
                    message: "请输入总人口数",
                    trigger: "blur",
                  },
                ],
                prop: "totalPopulationNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入总人口数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.totalPopulationNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "totalPopulationNum", $$v)
                  },
                  expression: "form.totalPopulationNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "常住人口数",
                rules: [
                  {
                    required: true,
                    message: "请输入常住人口数",
                    trigger: "blur",
                  },
                ],
                prop: "oftenPopulationNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入常住人口数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.oftenPopulationNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "oftenPopulationNum", $$v)
                  },
                  expression: "form.oftenPopulationNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "暂住人口数",
                rules: [
                  {
                    required: true,
                    message: "请输入暂住人口数",
                    trigger: "blur",
                  },
                ],
                prop: "temporaryPopulationNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入暂住人口数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.temporaryPopulationNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "temporaryPopulationNum", $$v)
                  },
                  expression: "form.temporaryPopulationNum",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }