var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "baseInfoForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitUrl: _vm.submitUrl,
              submitText: "保存",
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccess,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "规划总固定车位数",
                rules: [
                  {
                    required: true,
                    message: "请输入规划总固定车位数",
                    trigger: "blur",
                  },
                ],
                prop: "planFixedParkNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入规划总固定车位数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.planFixedParkNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "planFixedParkNum", $$v)
                  },
                  expression: "form.planFixedParkNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "规划地上总固定车位数",
                rules: [
                  {
                    required: true,
                    message: "请输入规划地上总固定车位数",
                    trigger: "blur",
                  },
                ],
                prop: "planUpperFixedParkNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入规划地上总固定车位数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.planUpperFixedParkNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "planUpperFixedParkNum", $$v)
                  },
                  expression: "form.planUpperFixedParkNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "规划地下总固定车位数",
                rules: [
                  {
                    required: true,
                    message: "请输入规划地下总固定车位数",
                    trigger: "blur",
                  },
                ],
                prop: "planDownFixedParkNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入规划地下总固定车位数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.planDownFixedParkNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "planDownFixedParkNum", $$v)
                  },
                  expression: "form.planDownFixedParkNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "其中：子母车位数",
                rules: [
                  {
                    required: true,
                    message: "请输入子母车位数",
                    trigger: "blur",
                  },
                ],
                prop: "sonAndMotherParkNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入子母车位数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.sonAndMotherParkNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sonAndMotherParkNum", $$v)
                  },
                  expression: "form.sonAndMotherParkNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "固定车位单价列举",
                rules: [
                  {
                    required: true,
                    message:
                      "请列举固定车位单价，**元/月，多个价格以“|”符号分隔",
                    trigger: "blur",
                  },
                ],
                prop: "fixedParkPrice",
              },
            },
            [
              _c("v-input", {
                attrs: {
                  placeholder:
                    "请列举固定车位单价，**元/月，多个价格以“|”符号分隔",
                  width: _vm.lwidth,
                },
                model: {
                  value: _vm.form.fixedParkPrice,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "fixedParkPrice", $$v)
                  },
                  expression: "form.fixedParkPrice",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "规划总临停车位数",
                rules: [
                  {
                    required: true,
                    message: "请输入规划总临停车位数",
                    trigger: "blur",
                  },
                ],
                prop: "planTemporaryParkNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入规划总临停车位数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.planTemporaryParkNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "planTemporaryParkNum", $$v)
                  },
                  expression: "form.planTemporaryParkNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "规划临停车位数单价列举",
                rules: [
                  {
                    required: true,
                    message:
                      "请列举规划临停车位数单价，**元/月，多个价格以“|”符号分隔",
                    trigger: "blur",
                  },
                ],
                prop: "temporaryParkPrice",
              },
            },
            [
              _c("v-input", {
                attrs: {
                  placeholder:
                    "请列举规划临停车位数单价，**元/月，多个价格以“|”符号分隔",
                  width: _vm.lwidth,
                },
                model: {
                  value: _vm.form.temporaryParkPrice,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "temporaryParkPrice", $$v)
                  },
                  expression: "form.temporaryParkPrice",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "规划总车位数",
                rules: [
                  {
                    required: true,
                    message: "总固定+总临停（剔除非标准车位）",
                    trigger: "blur",
                  },
                ],
                prop: "planParkNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "总固定+总临停（剔除非标准车位）",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.planParkNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "planParkNum", $$v)
                  },
                  expression: "form.planParkNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "车位比",
                rules: [
                  { required: true, message: "请输入车位比", trigger: "blur" },
                ],
                prop: "parkRate",
              },
            },
            [
              _c("v-input", {
                attrs: { placeholder: "请输入车位比", width: _vm.lwidth },
                model: {
                  value: _vm.form.parkRate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "parkRate", $$v)
                  },
                  expression: "form.parkRate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "规划产权车位数",
                rules: [
                  {
                    required: true,
                    message: "请输入规划产权车位数",
                    trigger: "blur",
                  },
                ],
                prop: "planPropertyParkNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入规划产权车位数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.planPropertyParkNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "planPropertyParkNum", $$v)
                  },
                  expression: "form.planPropertyParkNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "规划非产权车位数",
                rules: [
                  {
                    required: true,
                    message: "请输入规划非产权车位数",
                    trigger: "blur",
                  },
                ],
                prop: "planNotPropertyParkNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入规划非产权车位数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.planNotPropertyParkNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "planNotPropertyParkNum", $$v)
                  },
                  expression: "form.planNotPropertyParkNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "规划机械车位数",
                rules: [
                  {
                    required: true,
                    message: "请输入规划机械车位数",
                    trigger: "blur",
                  },
                ],
                prop: "planMechanicsParkNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入规划机械车位数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.planMechanicsParkNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "planMechanicsParkNum", $$v)
                  },
                  expression: "form.planMechanicsParkNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "规划人防车位数",
                rules: [
                  {
                    required: true,
                    message: "请输入规划人防车位数",
                    trigger: "blur",
                  },
                ],
                prop: "planCivilAirDefenseParkNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入规划人防车位数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.planCivilAirDefenseParkNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "planCivilAirDefenseParkNum", $$v)
                  },
                  expression: "form.planCivilAirDefenseParkNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "规划地上机动车位总数",
                rules: [
                  {
                    required: true,
                    message: "请输入规划地上机动车位总数",
                    trigger: "blur",
                  },
                ],
                prop: "planUpperVehicleParkNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入规划地上机动车位总数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.planUpperVehicleParkNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "planUpperVehicleParkNum", $$v)
                  },
                  expression: "form.planUpperVehicleParkNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "规划地下机动车位总数",
                rules: [
                  {
                    required: true,
                    message: "请输入规划地下机动车位总数",
                    trigger: "blur",
                  },
                ],
                prop: "planDownVehicleParkNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入规划地下机动车位总数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.planDownVehicleParkNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "planDownVehicleParkNum", $$v)
                  },
                  expression: "form.planDownVehicleParkNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "车位单价列举",
                rules: [
                  {
                    required: true,
                    message: "请列举车位单价，**元/月，多个价格以“|”符号分隔",
                    trigger: "blur",
                  },
                ],
                prop: "parkPrice",
              },
            },
            [
              _c("v-input", {
                attrs: {
                  placeholder: "请列举车位单价，**元/月，多个价格以“|”符号分隔",
                  width: _vm.lwidth,
                },
                model: {
                  value: _vm.form.parkPrice,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "parkPrice", $$v)
                  },
                  expression: "form.parkPrice",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "规划总车库数-带产权",
                rules: [
                  {
                    required: true,
                    message: "请输入规划总车库数-带产权",
                    trigger: "blur",
                  },
                ],
                prop: "planGarageNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入规划总车库数-带产权",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.planGarageNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "planGarageNum", $$v)
                  },
                  expression: "form.planGarageNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "车库单价列举",
                rules: [
                  {
                    required: true,
                    message: "请列举车库单价，**元/月，多个价格以“|”符号分隔",
                    trigger: "blur",
                  },
                ],
                prop: "garagePrice",
              },
            },
            [
              _c("v-input", {
                attrs: {
                  placeholder: "请列举车库单价，**元/月，多个价格以“|”符号分隔",
                  width: _vm.lwidth,
                },
                model: {
                  value: _vm.form.garagePrice,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "garagePrice", $$v)
                  },
                  expression: "form.garagePrice",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "规划非机动车位总数",
                rules: [
                  {
                    required: true,
                    message: "请输入规划非机动车位总数",
                    trigger: "blur",
                  },
                ],
                prop: "planNotVehicleParkNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入规划非机动车位总数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.planNotVehicleParkNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "planNotVehicleParkNum", $$v)
                  },
                  expression: "form.planNotVehicleParkNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "规划地上非机动车位总数",
                rules: [
                  {
                    required: true,
                    message: "请输入规划地上非机动车位总数",
                    trigger: "blur",
                  },
                ],
                prop: "planUpperNotVehicleNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入规划地上非机动车位总数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.planUpperNotVehicleNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "planUpperNotVehicleNum", $$v)
                  },
                  expression: "form.planUpperNotVehicleNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "规划地下非机动车位总数",
                rules: [
                  {
                    required: true,
                    message: "请输入规划地下非机动车位总数",
                    trigger: "blur",
                  },
                ],
                prop: "planDownNotVehicleNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入规划地下非机动车位总数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.planDownNotVehicleNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "planDownNotVehicleNum", $$v)
                  },
                  expression: "form.planDownNotVehicleNum",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }