<template>
  <div class="baseInfoForm-container">
    <form-panel ref="formPanel" :form="form"  v-bind="submitConfig" :submitUrl="submitUrl" submitText="保存" :submitBefore="submitBefore" :submitSuccess="submitSuccess">
      <el-form-item label="总电梯台数" :rules="[{ required: true, message: '请输入总电梯台数', trigger: 'blur' }]" prop="elevatorNum">
        <v-input-number v-model="form.elevatorNum" placeholder="请输入总电梯台数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="监控探头数" :rules="[{ required: true, message: '请输入监控探头数', trigger: 'blur' }]" prop="monitoringProbeNum">
        <v-input-number v-model="form.monitoringProbeNum" placeholder="请输入监控探头数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="户外消火栓数" :rules="[{ required: true, message: '请输入户外消火栓数', trigger: 'blur' }]" prop="outdoorsHydrantNum">
        <v-input-number v-model="form.outdoorsHydrantNum" placeholder="请输入户外消火栓数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="户内消火栓箱数" :rules="[{ required: true, message: '请输入户内消火栓箱数', trigger: 'blur' }]" prop="outdoorsHydrantBoxNum">
        <v-input-number v-model="form.outdoorsHydrantBoxNum" placeholder="请输入户内消火栓箱数" :max="99999" :width="width" :disabled="baseDisabled"></v-input-number>
      </el-form-item>
      <el-form-item label="高杆灯数" :rules="[{ required: true, message: '请输入高杆灯数', trigger: 'blur' }]" prop="highPoleLampNum">
        <v-input-number v-model="form.highPoleLampNum" placeholder="请输入高杆灯数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="草坪灯数" :rules="[{ required: true, message: '请输入草坪灯数', trigger: 'blur' }]" prop="lawnLampNum">
        <v-input-number v-model="form.lawnLampNum" placeholder="请输入草坪灯数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="排污泵数" :rules="[{ required: true, message: '请输入排污泵数', trigger: 'blur' }]" prop="sewagePumpNum">
        <v-input-number v-model="form.sewagePumpNum" placeholder="请输入排污泵数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="雨水井数" :rules="[{ required: true, message: '请输入雨水井数', trigger: 'blur' }]" prop="rainwaterWellNum">
        <v-input-number v-model="form.rainwaterWellNum" placeholder="请输入雨水井数" :max="99999" :width="width" :disabled="baseDisabled"></v-input-number>
      </el-form-item>
      <el-form-item label="太阳能热水器是否允许安装" :rules="[{ required: true, message: '请选择太阳能热水器是否允许安装', trigger: 'change' }]" prop="solarHeaterInstall">
        <checkbox-plus :value.sync="form.solarHeaterInstall" type="radio" :options="isYesOptions"></checkbox-plus>
      </el-form-item>
      <el-form-item label="是否人车分流" :rules="[{ required: true, message: '请选择是否人车分流', trigger: 'change' }]" prop="peopleVehiclesShunt">
        <checkbox-plus :value.sync="form.peopleVehiclesShunt" type="radio" :options="isYesOptions"></checkbox-plus>
      </el-form-item>
      <el-form-item label="消/监控室数" :rules="[{ required: true, message: '请输入消/监控室数', trigger: 'blur' }]" prop="monitoringRoomNum">
        <v-input-number v-model="form.monitoringRoomNum" placeholder="请输入消/监控室数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="电梯机房数" :rules="[{ required: true, message: '请输入电梯机房数', trigger: 'blur' }]" prop="elevatorRoomNum">
        <v-input-number v-model="form.elevatorRoomNum" placeholder="请输入电梯机房数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="生活/消防水泵房数" :rules="[{ required: true, message: '请输入生活/消防水泵房数', trigger: 'blur' }]" prop="firePumpRoomNum">
        <v-input-number v-model="form.firePumpRoomNum" placeholder="请输入生活/消防水泵房数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="配电房数" :rules="[{ required: true, message: '请输入配电房数', trigger: 'blur' }]" prop="distributionRoomNum">
        <v-input-number v-model="form.distributionRoomNum" placeholder="请输入配电房数" :max="99999" :width="width" :disabled="baseDisabled"></v-input-number>
      </el-form-item>
      <el-form-item label="风机房数" :rules="[{ required: true, message: '请输入风机房数', trigger: 'blur' }]" prop="fanRoomNum">
        <v-input-number v-model="form.fanRoomNum" placeholder="请输入风机房数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="发电机房数" :rules="[{ required: true, message: '请输入发电机房数', trigger: 'blur' }]" prop="alternatorRoomNum">
        <v-input-number v-model="form.alternatorRoomNum" placeholder="请输入发电机房数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      
    </form-panel>
  </div>
</template>
<script>
import {
  addCommunityEquipmentInfoURL
} from './api'
import {
  isYesOps
} from './map'
import { CheckboxPlus } from 'components/bussiness'
export default {
  components: { CheckboxPlus },
  props: {
    publicParams: Object,
    responseData: Object
  },
  data () {
    return {
      width: 260,
      submitUrl: addCommunityEquipmentInfoURL,
      form: {
        equipmentId: '',
        communityId: '',    
        elevatorNum: undefined, //总电梯台数
        monitoringProbeNum: undefined,   //监控探头数
        outdoorsHydrantNum: undefined, //户外消火栓数
        outdoorsHydrantBoxNum: undefined, //户内消火栓箱数
        highPoleLampNum: undefined, //高杆灯数
        lawnLampNum: undefined, //草坪灯数
        sewagePumpNum: undefined, //排污泵数
        rainwaterWellNum: undefined, //雨水井数
        solarHeaterInstall: undefined, //太阳能热水器是否允许安装 0否 1是
        peopleVehiclesShunt: undefined, //是否人车分流 0 否 1 是
        monitoringRoomNum: undefined, //消/监控室数
        elevatorRoomNum: undefined, //电梯机房数
        firePumpRoomNum: undefined, //生活/消防水泵房数
        distributionRoomNum: undefined, //配电房数
        fanRoomNum: undefined, //风机房数
        alternatorRoomNum: undefined //发电机房数
      },
      submitConfig: {
        submitMethod: 'post'
      },
      isYesOptions: isYesOps
    }
  },
  computed: {
    // 修改项目基础信息权限
    baseDisabled () {
      let baseEdit = this.$store.getters.getPermission('updateCommunityBase')
      return !baseEdit
    }
  },
  mounted () {
    this.responseData && this.responseData.data && this.setBaseInfo()
  },
  methods: {
    setBaseInfo () {
      let { communityEquipmentV } = this.responseData.data
      let keyArr = Object.keys(this.form)
      let newObj = {}
      keyArr.forEach(value => {
        newObj[value] = communityEquipmentV[value]
      })
      this.form = newObj
      this.form.solarHeaterInstall = this.form.solarHeaterInstall.toString()
      this.form.peopleVehiclesShunt = this.form.peopleVehiclesShunt.toString()
    },
    submitBefore (data) {
      console.log(data,'data')
      return true
    },
    submitSuccess (data) {
      if (this.form.equipmentId) {
        this.$message.success('修改成功')
        this.$emit('contact', {
          method: 'setActiveLabel',
          data: '财务信息'
        })     
        return false
      } else {
        this.$message.success('保存成功')
        this.$emit('contact', {
            method: 'setActiveLabel',
            data: '财务信息'
          })
        return false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.baseInfoForm-container {
  height: 100%;
}
::v-deep .el-form-item__label{
  width: 205px !important;
}
::v-deep .el-form-item__content{
  margin-left: 205px !important;
}
</style>
