<!--
 * @Description: 项目中心 > 项目初始化 > 项目信息配置 基础信息
 * @Author: FCC
 * @Date: 2019-08-06 08:45:23
 * @LastEditors: 小广
 * @LastEditTime: 2020-05-18 09:28:32
 -->
<template>
  <div class="baseInfoForm-container">
    <form-panel class="form-container" ref="formPanel" :form="form"  v-bind="submitConfig" :submitUrl="submitUrl" submitText="保存" :submitBefore="submitBefore" :submitSuccess="submitSuccess">
      <el-form-item label="项目名称" :rules="[{ required: true, message: '请输入项目名称', trigger: 'blur' }]" prop="communityName">
        <v-input v-model="form.communityName" placeholder="请输入项目名称" :width="lwidth" :disabled="baseDisabled"></v-input>
      </el-form-item>
      <el-form-item label="所属公司" :rules="[{ required: true, message: '请选择所属公司', trigger: 'change' }]" prop="tenantId">
        <v-select2 v-model="form.tenantId" :width="width" :disabled="baseDisabled" placeholder="查询公司名称" v-bind="regionParams" />
      </el-form-item>
      <el-form-item label="项目区划" :rules="[{ required: true, validator: validateAreaCode, trigger: 'change' }]" prop="shequCode">
        <area-select2 :value.sync="areaValue" placeholder="按区域选择" @change="areaChange" :width="500">
    	</area-select2>
      </el-form-item>
      <el-form-item label="项目地址" :rules="[{ required: true, message: '请输入项目地址', trigger: 'blur' }]" prop="communityAddress">
        <v-input v-model="form.communityAddress" placeholder="请输入项目地址" :width="500"></v-input>
      </el-form-item>
      <el-form-item label="项目类型" :rules="[{ required: true, message: '请选择项目类型', trigger: 'change' }]" prop="communityType">
        <v-select v-model="form.communityType" :options="communityTypeOps" :width="width" :disabled="baseDisabled"></v-select>
      </el-form-item>
      <el-form-item label="项目编号" :rules="[{ required: true, message: '请输入项目编号', trigger: 'blur' }]" prop="communityNumber">
        <v-input v-model="form.communityNumber" placeholder="请输入项目编号" :width="lwidth"></v-input>
      </el-form-item>
      <el-form-item label="所属区域" :rules="[{ required: true, message: '请输入所属区域', trigger: 'blur' }]" prop="communityRegion">
        <v-input v-model="form.communityRegion" placeholder="请输入所属区域" :width="lwidth"></v-input>
      </el-form-item>
      <el-form-item label="项目地名办备案名" :rules="[{ required: true, message: '请输入项目地名办备案名', trigger: 'blur' }]" prop="communityPlace">
        <v-input v-model="form.communityPlace" placeholder="请输入项目地名办备案名" :width="lwidth"></v-input>
      </el-form-item>
      <el-form-item label="项目销售名">
        <v-input v-model="form.communitySaleName" placeholder="请输入项目销售名" :width="lwidth"></v-input>
      </el-form-item>
      <el-form-item label="合同甲方" :rules="[{ required: true, message: '请输入物业合同甲方', trigger: 'blur' }]" prop="contractFirstParty">
        <v-input v-model="form.contractFirstParty" placeholder="请输入物业合同甲方" :width="lwidth"></v-input>
      </el-form-item>
      <el-form-item label="项目来源" :rules="[{ required: true, message: '请选择项目来源', trigger: 'change' }]" prop="communitySource">
        <checkbox-plus :value.sync="form.communitySource" type="radio" :options="projectFromOptions"></checkbox-plus>
      </el-form-item>

      <el-form-item label="行人出入口" prop="pedestrian">
        <div v-for="(item, index) in form.pedestrian" :key="index">
          <v-input v-model="item.doorName"></v-input>
          <v-select v-model="item.typeName" :options="doorTypeListOptions"></v-select>
        </div>
        <div>
          <el-button type="primary" size="small" @click="addDoor('pedestrian')">增加</el-button>
          <el-button v-if="form.pedestrian.length > 1" type="danger" size="small" @click="delDoor('pedestrian')">删除</el-button>
        </div>
      </el-form-item>
      <el-form-item label="机动车出入入口"  prop="motor">
        <div v-for="(item, index) in form.motor" :key="index">
          <v-input v-model="item.doorName"></v-input>
          <v-select v-model="item.typeName" :options="doorTypeListOptions"></v-select>
        </div>
        <div>
          <el-button type="primary" size="small" @click="addDoor('motor')">增加</el-button>
          <el-button v-if="form.motor.length > 1" type="danger" size="small" @click="delDoor('motor')">删除</el-button>
        </div>

      </el-form-item>
      <el-form-item label="非机动车出入口"  prop="nonMotor">
        <div v-for="(item, index) in form.nonMotor" :key="index">
          <v-input v-model="item.doorName"></v-input>
          <v-select v-model="item.typeName" :options="doorTypeListOptions"></v-select>
        </div>
        <div>
          <el-button type="primary" size="small" @click="addDoor('nonMotor')">增加</el-button>
          <el-button v-if="form.nonMotor.length > 1" type="danger" size="small" @click="delDoor('nonMotor')">删除</el-button>
        </div>
      </el-form-item>
      <el-form-item label="消防出入口"  prop="fireFighting">
        <div v-for="(item, index) in form.fireFighting" :key="index">
          <v-input v-model="item.doorName"></v-input>
          <v-select style="margin-left: 10px" v-model="item.typeName" :options="doorTypeListOptions"></v-select>
        </div>
        <div>
          <el-button type="primary" size="small" @click="addDoor('fireFighting')">增加</el-button>
          <el-button v-if="form.fireFighting.length > 1" type="danger" size="small" @click="delDoor('fireFighting')">删除</el-button>
        </div>
      </el-form-item>

      <el-form-item label="园区公告栏数" :rules="[{ required: true, message: '请输入园区公告栏数', trigger: 'blur' }]" prop="communityNoticeNum">
        <v-input-number v-model="form.communityNoticeNum" placeholder="请输入园区公告栏数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="项目楼幢类型概述" :rules="[{ required: true, message: '请输入项目楼幢类型', trigger: 'blur' }]" prop="buildingTypeDescribe">
        <v-input v-model="form.buildingTypeDescribe" placeholder="请输入项目楼幢类型（如，1-5幢为多层，6-8幢为别墅等）" :width="500"></v-input>
      </el-form-item>
      <el-form-item label="总楼幢数" :rules="[{ required: true, message: '请输入总楼幢数', trigger: 'blur' }]" prop="buildingNum">
        <v-input-number v-model="form.buildingNum" placeholder="请输入总楼幢数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="储藏间总数" :rules="[{ required: true, message: '请输入储藏间总数', trigger: 'blur' }]" prop="storeNum">
        <v-input-number v-model="form.storeNum" placeholder="请输入储藏间总数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
    </form-panel>
  </div>
</template>
<script>
import { regionParams } from 'common/select2Params'
import {
  getRoomTypesURL
} from './../api'
import {
  projectFromOps,
  doorTypeList
} from './map'
import {addAndUpdateCommunityInfoURL} from './api'
import { AreaSelect2, CheckboxPlus } from 'components/bussiness'

export default {
  components: { AreaSelect2, CheckboxPlus },
  props: {
    publicParams: Object,
    responseData: Object
  },
  data () {
    return {
      width: 180,
      lwidth: 300,
      submitUrl: addAndUpdateCommunityInfoURL,
      areaValue: [],
      regionParams,
      form: {
        baseId: '',//主键
        communityId: '',
        communityName: '',
        tenantId: '',
        addrCode: '',
        provinceCode: '',
        cityCode: '',
        communityAddress: '',
        communityType: undefined,
        countryCode: '',
        streetCode: '',
        shequCode: '',
        communityNumber: '',
        // 新加
        communityRegion: '', //所属区域-项目对应的区域、事业部
        communityPlace: '', //项目地名办备案名
        communitySaleName: '', //项目销售名
        communitySource: undefined, //项目来源 1,集团 2,外拓
        contractFirstParty: '',  //合同甲方
        buildingTypeDescribe: '', //项目楼幢类型概述
        mainExitNum: undefined, //主出入口数
        secondExitNum: undefined, //次出入口数
        fireExitNum: undefined, //消防出入口数
        communityNoticeNum: undefined, //园区公告栏数
        buildingNum: undefined, //总楼幢数
        storeNum: undefined,//储藏间总数
        fireFighting:[],
        pedestrian:[],
        motor:[],
        nonMotor:[]

      },
      submitConfig: {
        submitMethod: 'post'
      },
      communityTypeOps: [],
      editFirstFlag: false,
      projectFromOptions:projectFromOps,
      doorTypeListOptions:doorTypeList
    }
  },
  computed: {
    // 修改项目基础信息权限
    baseDisabled () {
      let baseEdit = this.$store.getters.getPermission('updateCommunityBase')
      let isEdit = this.$route.query.id !== undefined
      return !baseEdit && isEdit
    }
  },
  mounted () {
    this.getRoomTypes()
    this.responseData && this.responseData.data && this.setBaseInfo()
  },
  methods: {
    getRoomTypes () {
      this.$axios.get(getRoomTypesURL).then(res => {
        if (res.status === '100') {
          let { data } = res
          let ops = data.map((val) => {
            return {
              text: val.value,
              value: val.id
            }
          })
          this.communityTypeOps = ops
          if (this.form.communityType === undefined) {
            this.$set(this.form, 'communityType', ops[0].value)
          }
        }
      })
    },
    setBaseInfo () {
      console.log(this.responseData,'this.responseDatathis.responseData')
      this.editFirstFlag = true
      let { communityBaseInfoV } = this.responseData.data

        communityBaseInfoV.fireFighting = communityBaseInfoV.passageway ? JSON.parse(communityBaseInfoV.passageway).fireFighting : [];
        communityBaseInfoV.motor = communityBaseInfoV.passageway ? JSON.parse(communityBaseInfoV.passageway).motor : [];
        communityBaseInfoV.nonMotor = communityBaseInfoV.passageway ? JSON.parse(communityBaseInfoV.passageway).nonMotor : [];
        communityBaseInfoV.pedestrian = communityBaseInfoV.passageway ? JSON.parse(communityBaseInfoV.passageway).pedestrian : [];

      let keyArr = Object.keys(this.form)
      let newObj = {}
      keyArr.forEach(value => {
        console.log(value + ":"+ JSON.stringify(communityBaseInfoV[value]))
        newObj[value] = communityBaseInfoV[value]
      })
      this.form = newObj
      if(!this.form.communityType){
        this.form.communityType = undefined
      }
      if(this.form.communitySource == 0){
        this.form.communitySource = undefined
      }
      this.form.addrCode = communityBaseInfoV.countryAreaCode
      this.areaValue = [communityBaseInfoV.provinceAreaCode, communityBaseInfoV.cityAreaCode, communityBaseInfoV.countryAreaCode, communityBaseInfoV.streetAreaCode, communityBaseInfoV.shequAreaCode]
    },
    submitBefore (data) {
      var passageway = {
        fireFighting: data.fireFighting,
        motor: data.motor,
        nonMotor: data.nonMotor,
        pedestrian: data.pedestrian
      }
      data.passageway = JSON.stringify(passageway)
      console.log(data,'data')
      return true
    },
    submitSuccess (data) {
      if (this.form.baseId) {
        this.$message.success('修改成功')
        this.$emit('contact', {
          method: 'setActiveLabel',
          data: '面积信息'
        })
        return false
      } else {
        this.$message.success('保存成功')
        this.$emit('contact', {
            method: 'setActiveLabel',
            data: '面积信息'
          })
        return false
      }
    },
    areaChange (val) {
      if(this.editFirstFlag){
        this.editFirstFlag = false
        return
      } else {
        if (val.area) {
          this.form.addrCode = val.area.value
          this.form.cityCode = val.city.label
          this.form.countryCode = val.area.label
          this.form.provinceCode = val.province.label
          this.form.streetCode = val.street.label
          this.form.shequCode = val.shequ.label
        } else {
          this.form.addrCode = ''
          this.form.cityCode = ''
          this.form.countryCode = ''
          this.form.provinceCode = ''
          this.form.streetCode = ''
          this.form.shequCode = ''
        }
      }
    },
    validateAreaCode (rule, value, callback) {
      if (value.length > 0) {
        callback()
      } else {
        callback(new Error('请选择项目区划'))
      }
    },
    addDoor(val) {
      var door = {
        doorType: "人防",
        doorName: ""
      }
      switch (val) {
        case "pedestrian" :
          this.form.pedestrian.push(door)
          break;
        case "motor" :
          this.form.motor.push(door)
          break;
        case "nonMotor" :
          this.form.nonMotor.push(door)
          break;
        case "fireFighting" :
          this.form.fireFighting.push(door)
          break;
        default :
          break;
      }
    },
    delDoor(val) {
      switch (val) {
        case "pedestrian" :
          this.form.pedestrian.pop()
          break;
        case "motor" :
          this.form.motor.pop()
          break;
        case "nonMotor" :
          this.form.nonMotor.pop()
          break;
        case "fireFighting" :
          this.form.fireFighting.pop()
          break;
        default :
          break;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.baseInfoForm-container {
  // overflow: auto;
  height: 100%;
}
.marginBot {
  margin-bottom: 20px;
}
.inlineBox {
  display: inline;
  margin-right: 20px;
}
.posStyle {
  .v-control {
    margin-right: 15px;
  }
}
.t-text {
  padding-left: 20px;
  position: relative;
}
.tipIcon {
  position: absolute;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('./tipIcon.png') no-repeat;
  top: 0;
  left: 0;
}
.check-box {
  display: flex;
  flex-direction: row;
}

.cut-line-wrapper {
  width: 50%;
  height: 1px;
  border-bottom: 2px dashed #DCDCDC;
  margin: 20px;
}
</style>
