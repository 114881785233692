var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "baseInfoForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitUrl: _vm.submitUrl,
              submitText: "保存",
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccess,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "附件",
                rules: [
                  {
                    required: true,
                    message: "请上传相关资料",
                    trigger: "blur",
                  },
                ],
                prop: "annex",
              },
            },
            [
              _c("v-uploader2", {
                attrs: {
                  action: _vm.uploadURL,
                  filePath: "customerFiles",
                  tip: "最多允许上传10个文件,单个文件大小不允许超过50MB",
                  limit: 10,
                  beforeRemove: _vm.beforeRemove,
                  fileSize: 500,
                },
                model: {
                  value: _vm.form.annex,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "annex", $$v)
                  },
                  expression: "form.annex",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }