// 编辑项目基础信息
const addAndUpdateCommunityInfoURL = `${API_CONFIG.butlerBaseURL}communityInfo/addAndUpdateCommunityInfo`
// 编辑项目面积信息
const addCommunityAreaInfoURL = `${API_CONFIG.butlerBaseURL}communityInfo/addCommunityAreaInfo`
// 编辑项目业户信息
const addCommunityOwnerInfoURL = `${API_CONFIG.butlerBaseURL}communityInfo/addCommunityOwnerInfo`
// 编辑项目车位信息
const addCommunityParkInfoURL = `${API_CONFIG.butlerBaseURL}communityInfo/addCommunityParkInfo`
// 编辑项目设备信息
const addCommunityEquipmentInfoURL = `${API_CONFIG.butlerBaseURL}communityInfo/addCommunityEquipmentInfo`
// 编辑项目财务信息
const addCommunityFinanceInfoURL = `${API_CONFIG.butlerBaseURL}communityInfo/addCommunityFinanceInfo`
// 编辑项目附件信息
const addCommunityAnnexInfoURL = `${API_CONFIG.butlerBaseURL}communityInfo/addCommunityAnnexInfo`
// 上传
const uploadURL = `${API_CONFIG.uploadURL}/fileUploadNoLimit`
export {
  addAndUpdateCommunityInfoURL,
  addCommunityAnnexInfoURL,
  addCommunityAreaInfoURL,
  addCommunityEquipmentInfoURL,
  addCommunityFinanceInfoURL,
  addCommunityOwnerInfoURL,
  addCommunityParkInfoURL,
  uploadURL 
}
