var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "baseInfoForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitUrl: _vm.submitUrl,
              submitText: "保存",
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccess,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "总建筑面积",
                rules: [
                  {
                    required: true,
                    message: "请输入总建筑面积",
                    trigger: "blur",
                  },
                ],
                prop: "communityArea",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入总建筑面积",
                  max: 999999999.99,
                  precision: 2,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.communityArea,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "communityArea", $$v)
                  },
                  expression: "form.communityArea",
                },
              }),
              _vm._v(" m²\n    "),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "总用地面积",
                rules: [
                  {
                    required: true,
                    message: "请输入总用地面积",
                    trigger: "blur",
                  },
                ],
                prop: "landUseArea",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入总用地面积",
                  max: 999999999.99,
                  precision: 2,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.landUseArea,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "landUseArea", $$v)
                  },
                  expression: "form.landUseArea",
                },
              }),
              _vm._v(" m²\n    "),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "占地面积",
                rules: [
                  {
                    required: true,
                    message: "请输入占地面积",
                    trigger: "blur",
                  },
                ],
                prop: "landOccupationArea",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入占地面积",
                  max: 999999999.99,
                  precision: 2,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.landOccupationArea,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "landOccupationArea", $$v)
                  },
                  expression: "form.landOccupationArea",
                },
              }),
              _vm._v(" m²\n    "),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "四至边界",
                rules: [
                  {
                    required: true,
                    message: "请输入四至边界",
                    trigger: "blur",
                  },
                ],
                prop: "fourBoundary",
              },
            },
            [
              _c("v-input", {
                attrs: { placeholder: "请输入四至边界", width: _vm.lwidth },
                model: {
                  value: _vm.form.fourBoundary,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "fourBoundary", $$v)
                  },
                  expression: "form.fourBoundary",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "地上面积",
                rules: [
                  {
                    required: true,
                    message: "请输入地上面积",
                    trigger: "blur",
                  },
                ],
                prop: "landUpperArea",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入地上面积",
                  max: 999999999.99,
                  precision: 2,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.landUpperArea,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "landUpperArea", $$v)
                  },
                  expression: "form.landUpperArea",
                },
              }),
              _vm._v(" m²\n    "),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "地下面积",
                rules: [
                  {
                    required: true,
                    message: "请输入地下面积",
                    trigger: "blur",
                  },
                ],
                prop: "landDownArea",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入地下面积",
                  max: 999999999.99,
                  precision: 2,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.landDownArea,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "landDownArea", $$v)
                  },
                  expression: "form.landDownArea",
                },
              }),
              _vm._v(" m²\n    "),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "总收费面积",
                rules: [
                  {
                    required: true,
                    message: "请输入总收费面积",
                    trigger: "blur",
                  },
                ],
                prop: "chargeArea",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入总收费面积",
                  max: 999999999.99,
                  precision: 2,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.chargeArea,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "chargeArea", $$v)
                  },
                  expression: "form.chargeArea",
                },
              }),
              _vm._v(" m²\n    "),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "规划绿化面积",
                rules: [
                  {
                    required: true,
                    message: "请输入规划绿化面积",
                    trigger: "blur",
                  },
                ],
                prop: "planGreenArea",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入规划绿化面积",
                  max: 999999999.99,
                  precision: 2,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.planGreenArea,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "planGreenArea", $$v)
                  },
                  expression: "form.planGreenArea",
                },
              }),
              _vm._v(" m²\n    "),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "实测绿化面积",
                rules: [
                  {
                    required: true,
                    message: "请输入实测绿化面积",
                    trigger: "blur",
                  },
                ],
                prop: "actualGreenArea",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入实测绿化面积",
                  max: 999999999.99,
                  precision: 2,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.actualGreenArea,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "actualGreenArea", $$v)
                  },
                  expression: "form.actualGreenArea",
                },
              }),
              _vm._v(" m²\n    "),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "绿化率",
                rules: [
                  {
                    required: true,
                    message: "请输入绿化垂直投影面积之和与小区用地的比率",
                    trigger: "blur",
                  },
                ],
                prop: "greenRate",
              },
            },
            [
              _c("v-input", {
                attrs: {
                  placeholder: "绿化垂直投影面积之和与小区用地的比率",
                  width: _vm.lwidth,
                },
                model: {
                  value: _vm.form.greenRate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "greenRate", $$v)
                  },
                  expression: "form.greenRate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "水系面积",
                rules: [
                  {
                    required: true,
                    message: "请输入水系面积",
                    trigger: "blur",
                  },
                ],
                prop: "waterArea",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入水系面积",
                  max: 999999999.99,
                  precision: 2,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.waterArea,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "waterArea", $$v)
                  },
                  expression: "form.waterArea",
                },
              }),
              _vm._v(" m²\n    "),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "泳池面积",
                rules: [
                  {
                    required: true,
                    message: "请输入泳池面积",
                    trigger: "blur",
                  },
                ],
                prop: "poolArea",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入泳池面积",
                  max: 999999999.99,
                  precision: 2,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.poolArea,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "poolArea", $$v)
                  },
                  expression: "form.poolArea",
                },
              }),
              _vm._v(" m²\n    "),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "容积率",
                rules: [
                  {
                    required: true,
                    message: "请输入地上总建筑面积与用地面积的比率",
                    trigger: "blur",
                  },
                ],
                prop: "volumeRate",
              },
            },
            [
              _c("v-input", {
                attrs: {
                  placeholder: "地上总建筑面积与用地面积的比率",
                  width: _vm.lwidth,
                },
                model: {
                  value: _vm.form.volumeRate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "volumeRate", $$v)
                  },
                  expression: "form.volumeRate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "物业服务用房位置",
                rules: [
                  {
                    required: true,
                    message: "请输入物业服务用房位置",
                    trigger: "blur",
                  },
                ],
                prop: "propertyServicesHousePosition",
              },
            },
            [
              _c("v-input", {
                attrs: {
                  placeholder: "请输入物业服务用房位置",
                  width: _vm.lwidth,
                },
                model: {
                  value: _vm.form.propertyServicesHousePosition,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "propertyServicesHousePosition", $$v)
                  },
                  expression: "form.propertyServicesHousePosition",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "物业服务用房面积",
                rules: [
                  {
                    required: true,
                    message: "请输入物业服务用房面积",
                    trigger: "blur",
                  },
                ],
                prop: "propertyServicesHouseArea",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入物业服务用房面积",
                  max: 999999999.99,
                  precision: 2,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.propertyServicesHouseArea,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "propertyServicesHouseArea", $$v)
                  },
                  expression: "form.propertyServicesHouseArea",
                },
              }),
              _vm._v(" m²\n    "),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "经营性用房位置",
                rules: [
                  {
                    required: true,
                    message: "请输入经营性用房位置",
                    trigger: "blur",
                  },
                ],
                prop: "managementHousePosition",
              },
            },
            [
              _c("v-input", {
                attrs: {
                  placeholder: "请输入经营性用房位置",
                  width: _vm.lwidth,
                },
                model: {
                  value: _vm.form.managementHousePosition,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "managementHousePosition", $$v)
                  },
                  expression: "form.managementHousePosition",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "经营性用房面积",
                rules: [
                  {
                    required: true,
                    message: "请输入经营性用房面积",
                    trigger: "blur",
                  },
                ],
                prop: "managementHouseArea",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入经营性用房面积",
                  max: 999999999.99,
                  precision: 2,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.managementHouseArea,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "managementHouseArea", $$v)
                  },
                  expression: "form.managementHouseArea",
                },
              }),
              _vm._v(" m²\n    "),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "会馆地址",
                rules: [
                  {
                    required: true,
                    message: "请输入会馆地址",
                    trigger: "blur",
                  },
                ],
                prop: "hallAddress",
              },
            },
            [
              _c("v-input", {
                attrs: { placeholder: "请输入会馆地址", width: _vm.lwidth },
                model: {
                  value: _vm.form.hallAddress,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "hallAddress", $$v)
                  },
                  expression: "form.hallAddress",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "会馆面积",
                rules: [
                  {
                    required: true,
                    message: "请输入会馆面积",
                    trigger: "blur",
                  },
                ],
                prop: "hallArea",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入会馆面积",
                  max: 999999999.99,
                  precision: 2,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.hallArea,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "hallArea", $$v)
                  },
                  expression: "form.hallArea",
                },
              }),
              _vm._v(" m²\n    "),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "社区用房面积",
                rules: [
                  {
                    required: true,
                    message: "请输入社区用房面积",
                    trigger: "blur",
                  },
                ],
                prop: "communityHouseArea",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入社区用房面积",
                  max: 999999999.99,
                  precision: 2,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.communityHouseArea,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "communityHouseArea", $$v)
                  },
                  expression: "form.communityHouseArea",
                },
              }),
              _vm._v(" m²\n    "),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }