<template>
  <div class="baseInfoForm-container">
    <form-panel ref="formPanel" :form="form"  v-bind="submitConfig" :submitUrl="submitUrl" submitText="保存" :submitBefore="submitBefore" :submitSuccess="submitSuccess">
      <el-form-item label="物业费定价原则" :rules="[{ required: true, message: '请选择物业费定价原则', trigger: 'change' }]" prop="propertyFeePricePrinciple">
        <checkbox-plus :value.sync="form.propertyFeePricePrinciple" type="radio" :options="propertyFeePricePrincipleOps"></checkbox-plus>
      </el-form-item>
      <el-form-item label="物业费单价列举" :rules="[{ required: true, message: '请输入物业费单价列举', trigger: 'blur' }]" prop="propertyFeePrice">
        <v-input v-model="form.propertyFeePrice" placeholder="2.5元/平/月、3.1元/平/月" :width="width"></v-input>
      </el-form-item>
      <el-form-item label="能耗费单价" :rules="[{ required: true, message: '请输入能耗费单价', trigger: 'blur' }]" prop="energyConsumptionFeePrice">
        <v-input-number v-model="form.energyConsumptionFeePrice" placeholder="请输入能耗费单价" :max="999999999.99" :precision="2" :width="width"></v-input-number> 元/平/月
      </el-form-item>
      <el-form-item label="能耗费类型" :rules="[{ required: true, message: '请选择能耗费类型', trigger: 'change' }]" prop="energyConsumptionFeeType">
        <checkbox-plus :value.sync="form.energyConsumptionFeeType" type="radio" :options="energyConsumptionFeeTypeOps"></checkbox-plus>
      </el-form-item>
      <el-form-item label="年度饱和收入" :rules="[{ required: true, message: '请输入年度饱和收入', trigger: 'blur' }]" prop="annualSaturatedIncome">
        <v-input-number v-model="form.annualSaturatedIncome" placeholder="请输入年度饱和收入" :max="999999999.99" :precision="2" :width="width"></v-input-number> 元
      </el-form-item>    
      <el-form-item label="是否单一产权" :rules="[{ required: true, message: '请选择是否单一产权', trigger: 'change' }]" prop="singlePropertyRight">
        <checkbox-plus :value.sync="form.singlePropertyRight" type="radio" :options="isYesOps"></checkbox-plus>
      </el-form-item>
      <el-form-item label="物业类型" :rules="[{ required: true, message: '请选择物业类型', trigger: 'change' }]" prop="propertyType">
        <checkbox-plus :value.sync="form.propertyType" type="radio" :options="propertyTypeOps"></checkbox-plus>
      </el-form-item>
      <el-form-item label="计费模式" :rules="[{ required: true, message: '请选择计费模式', trigger: 'change' }]" prop="billingMode">
        <checkbox-plus :value.sync="form.billingMode" type="radio" :options="billingModeOps"></checkbox-plus>
      </el-form-item>
      <el-form-item label="业务模式" :rules="[{ required: true, message: '请选择业务模式', trigger: 'change' }]" prop="businessModel">
        <checkbox-plus :value.sync="form.businessModel" type="radio" :options="businessModelOps"></checkbox-plus>
      </el-form-item>
      
    </form-panel>
  </div>
</template>
<script>
import {
  addCommunityFinanceInfoURL
} from './api'
import {
  propertyFeePricePrincipleOps,
  energyConsumptionFeeTypeOps,
  isYesOps,
  propertyTypeOps,
  billingModeOps,
  businessModelOps
} from './map'
import { CheckboxPlus } from 'components/bussiness'
export default {
  components: { CheckboxPlus },
  props: {
    publicParams: Object,
    responseData: Object
  },
  data () {
    return {
      width: 260,
      submitUrl: addCommunityFinanceInfoURL,
      form: {
        financeId: '',
        communityId: '',    
        propertyFeePricePrinciple: undefined, //物业费定价原则 1 政府指导价 2 市场调节价
        propertyFeePrice: '',   //物业费单价列举
        energyConsumptionFeePrice: undefined, //能耗费单价
        energyConsumptionFeeType: undefined, //能耗费类型 1,定额 2,按时分摊
        annualSaturatedIncome: undefined, //年度饱和收入
        singlePropertyRight: undefined, //是否单一产权 0 否 1 是
        propertyType: undefined, //物业类型 1,包干 2,酬金
        billingMode: undefined, //计费模式  1,一费制 2 非一费制
        businessModel: undefined, //业务模式 1 全委 2 单向       
      },
      submitConfig: {
        submitMethod: 'post'
      },
      propertyFeePricePrincipleOps,
      energyConsumptionFeeTypeOps,
      isYesOps,
      propertyTypeOps,
      billingModeOps,
      businessModelOps
    }
  },
  computed: {
    // 修改项目基础信息权限
    baseDisabled () {
      let baseEdit = this.$store.getters.getPermission('updateCommunityBase')
      return !baseEdit
    }
  },
  mounted () {
    this.responseData && this.responseData.data && this.setBaseInfo()
  },
  methods: {
    setBaseInfo () {
      let { communityFinanceV } = this.responseData.data
      let keyArr = Object.keys(this.form)
      let newObj = {}
      keyArr.forEach(value => {
        newObj[value] = communityFinanceV[value]
      })
      this.form = newObj
      this.form.singlePropertyRight = this.form.singlePropertyRight.toString()
      let keyZero = ['propertyFeePricePrinciple','energyConsumptionFeeType','propertyType','billingMode','businessModel']
      keyZero.forEach(value => {
        if(this.form[value]==0) {
          this.form[value] = undefined
        }
      })
    },
    submitBefore (data) {
      console.log(data,'data')
      return true
    },
    submitSuccess (data) {
      if (this.form.financeId) {
        this.$message.success('修改成功')
        this.$emit('contact', {
          method: 'setActiveLabel',
          data: '附件信息'
        })     
        return false
      } else {
        this.$message.success('保存成功')
        this.$emit('contact', {
            method: 'setActiveLabel',
            data: '附件信息'
          })
        return false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.baseInfoForm-container {
  height: 100%;
}
::v-deep .el-form-item__label{
  width: 170px !important;
}
</style>
