var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "baseInfoForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitUrl: _vm.submitUrl,
              submitText: "保存",
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccess,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "物业费定价原则",
                rules: [
                  {
                    required: true,
                    message: "请选择物业费定价原则",
                    trigger: "change",
                  },
                ],
                prop: "propertyFeePricePrinciple",
              },
            },
            [
              _c("checkbox-plus", {
                attrs: {
                  value: _vm.form.propertyFeePricePrinciple,
                  type: "radio",
                  options: _vm.propertyFeePricePrincipleOps,
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(
                      _vm.form,
                      "propertyFeePricePrinciple",
                      $event
                    )
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "物业费单价列举",
                rules: [
                  {
                    required: true,
                    message: "请输入物业费单价列举",
                    trigger: "blur",
                  },
                ],
                prop: "propertyFeePrice",
              },
            },
            [
              _c("v-input", {
                attrs: {
                  placeholder: "2.5元/平/月、3.1元/平/月",
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.propertyFeePrice,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "propertyFeePrice", $$v)
                  },
                  expression: "form.propertyFeePrice",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "能耗费单价",
                rules: [
                  {
                    required: true,
                    message: "请输入能耗费单价",
                    trigger: "blur",
                  },
                ],
                prop: "energyConsumptionFeePrice",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入能耗费单价",
                  max: 999999999.99,
                  precision: 2,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.energyConsumptionFeePrice,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "energyConsumptionFeePrice", $$v)
                  },
                  expression: "form.energyConsumptionFeePrice",
                },
              }),
              _vm._v(" 元/平/月\n    "),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "能耗费类型",
                rules: [
                  {
                    required: true,
                    message: "请选择能耗费类型",
                    trigger: "change",
                  },
                ],
                prop: "energyConsumptionFeeType",
              },
            },
            [
              _c("checkbox-plus", {
                attrs: {
                  value: _vm.form.energyConsumptionFeeType,
                  type: "radio",
                  options: _vm.energyConsumptionFeeTypeOps,
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(
                      _vm.form,
                      "energyConsumptionFeeType",
                      $event
                    )
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "年度饱和收入",
                rules: [
                  {
                    required: true,
                    message: "请输入年度饱和收入",
                    trigger: "blur",
                  },
                ],
                prop: "annualSaturatedIncome",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入年度饱和收入",
                  max: 999999999.99,
                  precision: 2,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.annualSaturatedIncome,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "annualSaturatedIncome", $$v)
                  },
                  expression: "form.annualSaturatedIncome",
                },
              }),
              _vm._v(" 元\n    "),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "是否单一产权",
                rules: [
                  {
                    required: true,
                    message: "请选择是否单一产权",
                    trigger: "change",
                  },
                ],
                prop: "singlePropertyRight",
              },
            },
            [
              _c("checkbox-plus", {
                attrs: {
                  value: _vm.form.singlePropertyRight,
                  type: "radio",
                  options: _vm.isYesOps,
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(_vm.form, "singlePropertyRight", $event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "物业类型",
                rules: [
                  {
                    required: true,
                    message: "请选择物业类型",
                    trigger: "change",
                  },
                ],
                prop: "propertyType",
              },
            },
            [
              _c("checkbox-plus", {
                attrs: {
                  value: _vm.form.propertyType,
                  type: "radio",
                  options: _vm.propertyTypeOps,
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(_vm.form, "propertyType", $event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "计费模式",
                rules: [
                  {
                    required: true,
                    message: "请选择计费模式",
                    trigger: "change",
                  },
                ],
                prop: "billingMode",
              },
            },
            [
              _c("checkbox-plus", {
                attrs: {
                  value: _vm.form.billingMode,
                  type: "radio",
                  options: _vm.billingModeOps,
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(_vm.form, "billingMode", $event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "业务模式",
                rules: [
                  {
                    required: true,
                    message: "请选择业务模式",
                    trigger: "change",
                  },
                ],
                prop: "businessModel",
              },
            },
            [
              _c("checkbox-plus", {
                attrs: {
                  value: _vm.form.businessModel,
                  type: "radio",
                  options: _vm.businessModelOps,
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(_vm.form, "businessModel", $event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }