<template>
  <div class="baseInfoForm-container">
    <form-panel ref="formPanel" :form="form"  v-bind="submitConfig" :submitUrl="submitUrl" submitText="保存" :submitBefore="submitBefore" :submitSuccess="submitSuccess">
      <el-form-item label="总户数" :rules="[{ required: true, message: '请输入项目规划的住宅与商铺总户数', trigger: 'blur' }]" prop="totalHouseNum">
        <v-input-number v-model="form.totalHouseNum" placeholder="请输入项目规划的住宅与商铺总户数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="未售户数" :rules="[{ required: true, message: '请输入未售户数', trigger: 'blur' }]" prop="notSellNum">
        <v-input-number v-model="form.notSellNum" placeholder="请输入未售户数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="已售未领户数" :rules="[{ required: true, message: '请输入已售未领户数', trigger: 'blur' }]" prop="sellNotReceiveNum">
        <v-input-number v-model="form.sellNotReceiveNum" placeholder="请输入已售未领户数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="已交付户数（领房）" :rules="[{ required: true, message: '请输入已交付的户数', trigger: 'blur' }]" prop="deliveryCount">
        <v-input-number v-model="form.deliveryCount" placeholder="请输入已交付的户数" :max="99999" :width="width" :disabled="baseDisabled"></v-input-number>
      </el-form-item>
      <el-form-item label="已交付面积" :rules="[{ required: true, message: '请输入已交付面积数', trigger: 'blur' }]" prop="deliverHouseArea">
        <v-input-number v-model="form.deliverHouseArea" placeholder="请输入已交付面积数" :max="999999999.99" :precision="2" :width="width"></v-input-number> m²
      </el-form-item>
      <el-form-item label="交付方式" :rules="[{ required: true, message: '请选择交付方式', trigger: 'change' }]" prop="deliverType">
        <checkbox-plus :value.sync="form.deliverType" type="radio" :options="deliverTypeOptions"></checkbox-plus>
      </el-form-item>
      <el-form-item
        label="交付日期"
        prop="deliverDate"
        :rules="[{ required: true, message: '请输入交付日期', trigger: 'change' }]"
      > 
        <v-input v-model="form.deliverDate" placeholder="请输入交付日期" :width="width"></v-input>
        <!-- <v-datepicker v-model="form.deliverDate" type="date" :width="width"/> -->
      </el-form-item>
      <el-form-item label="在装修户数" :rules="[{ required: true, message: '请输入在装修户数', trigger: 'blur' }]" prop="renovationHouseNum">
        <v-input-number v-model="form.renovationHouseNum" placeholder="请输入在装修户数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="装修未住户数" :rules="[{ required: true, message: '请输入装修未住户数', trigger: 'blur' }]" prop="renovationNotLiveNum">
        <v-input-number v-model="form.renovationNotLiveNum" placeholder="请输入装修未住户数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="已入住户数" :rules="[{ required: true, message: '请输入已入住户数', trigger: 'blur' }]" prop="totalLiveRoomCount">
        <v-input-number v-model="form.totalLiveRoomCount" placeholder="请输入已入住户数" :max="99999" :width="width" :disabled="baseDisabled"></v-input-number>
      </el-form-item>
      <el-form-item label="常住户数" :rules="[{ required: true, message: '请输入常住户数', trigger: 'blur' }]" prop="oftenHouseNum">
        <v-input-number v-model="form.oftenHouseNum" placeholder="请输入常住户数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item
        label="业委会成立日期"
      >
        <v-input v-model="form.committeeDate" placeholder="请输入业委会成立日期" :width="width"></v-input>
        <!-- <v-datepicker v-model="form.committeeDate" type="date" :width="width"/> -->
      </el-form-item>
      <el-form-item label="总人口数" :rules="[{ required: true, message: '请输入总人口数', trigger: 'blur' }]" prop="totalPopulationNum">
        <v-input-number v-model="form.totalPopulationNum" placeholder="请输入总人口数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="常住人口数" :rules="[{ required: true, message: '请输入常住人口数', trigger: 'blur' }]" prop="oftenPopulationNum">
        <v-input-number v-model="form.oftenPopulationNum" placeholder="请输入常住人口数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="暂住人口数" :rules="[{ required: true, message: '请输入暂住人口数', trigger: 'blur' }]" prop="temporaryPopulationNum">
        <v-input-number v-model="form.temporaryPopulationNum" placeholder="请输入暂住人口数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      
    </form-panel>
  </div>
</template>
<script>
import {
  addCommunityOwnerInfoURL
} from './api'
import {
  deliverTypeOps
} from './map'
import { CheckboxPlus } from 'components/bussiness'
export default {
  components: { CheckboxPlus },
  props: {
    publicParams: Object,
    responseData: Object
  },
  data () {
    return {
      width: 260,
      submitUrl: addCommunityOwnerInfoURL,
      form: {
        ownerId: '',
        communityId: '',    
        totalHouseNum: undefined, //总户数
        notSellNum: undefined,   //未售户数
        sellNotReceiveNum: undefined, //已售未领户数
        deliveryCount: undefined, //已交付户数（领房）
        deliverHouseArea: undefined, //已交付面积
        deliverType: undefined, //交付方式1,一次性交付,2滚动交付
        deliverDate: '', //交付日期
        renovationHouseNum: undefined, //在装修户数
        renovationNotLiveNum: undefined, //装修未住户数
        oftenHouseNum: undefined, //常住户数
        committeeDate: '', //业委会成立日期
        totalPopulationNum: undefined, //总人口数
        oftenPopulationNum: undefined, //常住人口数
        temporaryPopulationNum: undefined, //暂住人口数
        totalLiveRoomCount: undefined //已入住户数
      },
      submitConfig: {
        submitMethod: 'post'
      },
      deliverTypeOptions:deliverTypeOps
    }
  },
  computed: {
    // 修改项目基础信息权限
    baseDisabled () {
      let baseEdit = this.$store.getters.getPermission('updateCommunityBase')
      return !baseEdit
    }
  },
  mounted () {
    this.responseData && this.responseData.data && this.setBaseInfo()
  },
  methods: {
    setBaseInfo () {
      let { communityOwnerV } = this.responseData.data
      let keyArr = Object.keys(this.form)
      let newObj = {}
      keyArr.forEach(value => {
        newObj[value] = communityOwnerV[value]
      })
      this.form = newObj
      if(this.form.deliverType == 0){
        this.form.deliverType = undefined
      }
    },
    submitBefore (data) {
      console.log(data,'data')
      return true
    },
    submitSuccess (data) {
      if (this.form.ownerId) {
        this.$message.success('修改成功')
        this.$emit('contact', {
          method: 'setActiveLabel',
          data: '车位信息'
        })     
        return false
      } else {
        this.$message.success('保存成功')
        this.$emit('contact', {
            method: 'setActiveLabel',
            data: '车位信息'
          })
        return false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.baseInfoForm-container {
  height: 100%;
}
::v-deep .el-form-item__label{
  width: 170px !important;
}
::v-deep .el-form-item__content{
  margin-left: 170px !important;
}
</style>
