var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "baseInfoForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitUrl: _vm.submitUrl,
              submitText: "保存",
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccess,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "总电梯台数",
                rules: [
                  {
                    required: true,
                    message: "请输入总电梯台数",
                    trigger: "blur",
                  },
                ],
                prop: "elevatorNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入总电梯台数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.elevatorNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "elevatorNum", $$v)
                  },
                  expression: "form.elevatorNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "监控探头数",
                rules: [
                  {
                    required: true,
                    message: "请输入监控探头数",
                    trigger: "blur",
                  },
                ],
                prop: "monitoringProbeNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入监控探头数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.monitoringProbeNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "monitoringProbeNum", $$v)
                  },
                  expression: "form.monitoringProbeNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "户外消火栓数",
                rules: [
                  {
                    required: true,
                    message: "请输入户外消火栓数",
                    trigger: "blur",
                  },
                ],
                prop: "outdoorsHydrantNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入户外消火栓数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.outdoorsHydrantNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "outdoorsHydrantNum", $$v)
                  },
                  expression: "form.outdoorsHydrantNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "户内消火栓箱数",
                rules: [
                  {
                    required: true,
                    message: "请输入户内消火栓箱数",
                    trigger: "blur",
                  },
                ],
                prop: "outdoorsHydrantBoxNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入户内消火栓箱数",
                  max: 99999,
                  width: _vm.width,
                  disabled: _vm.baseDisabled,
                },
                model: {
                  value: _vm.form.outdoorsHydrantBoxNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "outdoorsHydrantBoxNum", $$v)
                  },
                  expression: "form.outdoorsHydrantBoxNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "高杆灯数",
                rules: [
                  {
                    required: true,
                    message: "请输入高杆灯数",
                    trigger: "blur",
                  },
                ],
                prop: "highPoleLampNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入高杆灯数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.highPoleLampNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "highPoleLampNum", $$v)
                  },
                  expression: "form.highPoleLampNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "草坪灯数",
                rules: [
                  {
                    required: true,
                    message: "请输入草坪灯数",
                    trigger: "blur",
                  },
                ],
                prop: "lawnLampNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入草坪灯数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.lawnLampNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "lawnLampNum", $$v)
                  },
                  expression: "form.lawnLampNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "排污泵数",
                rules: [
                  {
                    required: true,
                    message: "请输入排污泵数",
                    trigger: "blur",
                  },
                ],
                prop: "sewagePumpNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入排污泵数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.sewagePumpNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sewagePumpNum", $$v)
                  },
                  expression: "form.sewagePumpNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "雨水井数",
                rules: [
                  {
                    required: true,
                    message: "请输入雨水井数",
                    trigger: "blur",
                  },
                ],
                prop: "rainwaterWellNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入雨水井数",
                  max: 99999,
                  width: _vm.width,
                  disabled: _vm.baseDisabled,
                },
                model: {
                  value: _vm.form.rainwaterWellNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "rainwaterWellNum", $$v)
                  },
                  expression: "form.rainwaterWellNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "太阳能热水器是否允许安装",
                rules: [
                  {
                    required: true,
                    message: "请选择太阳能热水器是否允许安装",
                    trigger: "change",
                  },
                ],
                prop: "solarHeaterInstall",
              },
            },
            [
              _c("checkbox-plus", {
                attrs: {
                  value: _vm.form.solarHeaterInstall,
                  type: "radio",
                  options: _vm.isYesOptions,
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(_vm.form, "solarHeaterInstall", $event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "是否人车分流",
                rules: [
                  {
                    required: true,
                    message: "请选择是否人车分流",
                    trigger: "change",
                  },
                ],
                prop: "peopleVehiclesShunt",
              },
            },
            [
              _c("checkbox-plus", {
                attrs: {
                  value: _vm.form.peopleVehiclesShunt,
                  type: "radio",
                  options: _vm.isYesOptions,
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(_vm.form, "peopleVehiclesShunt", $event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "消/监控室数",
                rules: [
                  {
                    required: true,
                    message: "请输入消/监控室数",
                    trigger: "blur",
                  },
                ],
                prop: "monitoringRoomNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入消/监控室数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.monitoringRoomNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "monitoringRoomNum", $$v)
                  },
                  expression: "form.monitoringRoomNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "电梯机房数",
                rules: [
                  {
                    required: true,
                    message: "请输入电梯机房数",
                    trigger: "blur",
                  },
                ],
                prop: "elevatorRoomNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入电梯机房数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.elevatorRoomNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "elevatorRoomNum", $$v)
                  },
                  expression: "form.elevatorRoomNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "生活/消防水泵房数",
                rules: [
                  {
                    required: true,
                    message: "请输入生活/消防水泵房数",
                    trigger: "blur",
                  },
                ],
                prop: "firePumpRoomNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入生活/消防水泵房数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.firePumpRoomNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "firePumpRoomNum", $$v)
                  },
                  expression: "form.firePumpRoomNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "配电房数",
                rules: [
                  {
                    required: true,
                    message: "请输入配电房数",
                    trigger: "blur",
                  },
                ],
                prop: "distributionRoomNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入配电房数",
                  max: 99999,
                  width: _vm.width,
                  disabled: _vm.baseDisabled,
                },
                model: {
                  value: _vm.form.distributionRoomNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "distributionRoomNum", $$v)
                  },
                  expression: "form.distributionRoomNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "风机房数",
                rules: [
                  {
                    required: true,
                    message: "请输入风机房数",
                    trigger: "blur",
                  },
                ],
                prop: "fanRoomNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入风机房数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.fanRoomNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "fanRoomNum", $$v)
                  },
                  expression: "form.fanRoomNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "发电机房数",
                rules: [
                  {
                    required: true,
                    message: "请输入发电机房数",
                    trigger: "blur",
                  },
                ],
                prop: "alternatorRoomNum",
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入发电机房数",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.alternatorRoomNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "alternatorRoomNum", $$v)
                  },
                  expression: "form.alternatorRoomNum",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }